/*------------------------------------------------------------------
[ Global ]*/

body,html{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #666666;
  font-size: medium;
  overflow-x: hidden;
}

*,h1,h2,h3,h4,h5,h6,p,a {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
a{
  color: unset;  
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
}
a:hover{
  color: #0E984A; 
  filter: brightness(1.1);
  text-decoration: none;
}
a:focus {
  outline: none;
}
.height-fullscreen{
  height: 100vh;
}


/*------------------------------------------------------------------
[  ]*/

.active-item-product{
  display: none;
}
.position-relative{
  position: relative;
}
.point-green-r{
  right: 70%;
  top: 50%;
  width: 65%;
  height: 50%;
}
.point-green-l{
  left: 68%;
  top: 0%;
  width: 65%;
  height: 50%;
}
.point-yellow-l{
  left: 70%;
  bottom: -50%;
  width: 65%;
  height: 80%;
}
.point-border-green{
  height: 40%;
  bottom: 35px;
  left: 76%;
  z-index: 1;
}
.index-2{
  z-index: 2;
}
.product-detail{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.animated-none {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
}
.bg-hover{
  background: rgba(87,195,238,0.5);
}

/*------------------------------------------------------------------
[ text ]*/
.text-indent{
  text-indent: 3em;
}
.text-green{
  color: #0E984A;
}
.text-yellow{
  color: #FFF177;
}
.text-blue{
  color: #7EDFFB;  
}
.text-facebook {
	color: #3b5998;
}
.text-line {
	color: #00c300;
}
.text-stroke{
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #0E984A;
}

/*------------------------------------------------------------------
[ border ]*/
.border-green{
  border: solid 2px #0E984A;
  @media only screen and (max-width : 575.98px){  
    border:0px;
  }
}
.border-white{
  border: solid 2px #ffffff;
}

/*------------------------------------------------------------------
[ diamond ]*/
.diamond-25{
  -webkit-clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
}
.diamond-5{
  -webkit-clip-path: polygon(5% 0%, 100% 0%, 95% 100%,  0% 100%);
  clip-path: polygon(5% 0%, 100% 0%, 95% 100%,  0% 100%);
}
.diamond{
  -ms-transform: skewX(-30deg); /* IE 9 */
  -webkit-transform: skewX(-30deg); /* Safari */
  transform: skewX(-30deg); /* Standard syntax */
  margin: 0 auto;
}

.diamond-full{
  width: 100%;
  -ms-transform: skewX(-30deg); /* IE 9 */
  -webkit-transform: skewX(-30deg); /* Safari */
  transform: skewX(-30deg); /* Standard syntax */
  margin: 0 auto;
  @media only screen and (max-width : 991.98px){ 
    -ms-transform: skewX(0deg); /* IE 9 */
    -webkit-transform: skewX(0deg); /* Safari */
    transform: skewX(0deg); /* Standard syntax */
  }
}

.diamond-border-green{
  width: 70%;
  background-color: #ffffff;
  border: solid 2px #0E984A;
  position: relative;
  -ms-transform: skewX(-30deg); /* IE 9 */
  -webkit-transform: skewX(-30deg); /* Safari */
  transform: skewX(-30deg); /* Standard syntax */
  @media only screen and (max-width : 991.98px){ 
    width: 100%;
    -ms-transform: skewX(0deg); /* IE 9 */
    -webkit-transform: skewX(0deg); /* Safari */
    transform: skewX(0deg); /* Standard syntax */
  }
}
.d-27{
  -ms-transform: skewX(30deg); /* IE 9 */
  -webkit-transform: skewX(30deg); /* Safari */
  transform: skewX(30deg); /* Standard syntax */
  @media only screen and (max-width : 991.98px){ 
    -ms-transform: skewX(0deg); /* IE 9 */
    -webkit-transform: skewX(0deg); /* Safari */
    transform: skewX(0deg); /* Standard syntax */
  }
}

/*------------------------------------------------------------------
[ background ]*/
.bg-yellow1{
  background-color: #FAFFF0;
}
.bg-yellow2{
  background-color: #FFF177;
}
.bg-green{
  background-color: #81B556;
}
.bg-green-dark{
  background-color:  #0E984A;
}
.bg-blue{
  background-color: #7EDFFB;
}
.frame{
  background-image: url('http://www.aandacontrol.com/asset/images/frame-left.png'), url('http://www.aandacontrol.com/asset/images/frame-right.png');
  background-repeat: no-repeat, no-repeat;
  background-size: auto 100%, auto 100%;
  background-position: left top, right bottom;
  @media only screen and (max-width : 991.98px){ 
    background-size: auto 35%, auto 35%;
  }
}
.bg-g-tb {
  background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(230, 230, 230, 0.7) 76%);
  background: -o-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(230, 230, 230, 0.7) 76%);
  background: -moz-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(230, 230, 230, 0.7) 76%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(230, 230, 230, 0.7) 76%);
}
.bg-image1 {
  background: linear-gradient(120deg,#81B556 10%, transparent 80%), url('http://www.aandacontrol.com/asset/images/bg1.png') no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-image2 {
  background: linear-gradient(to right, rgba(14,152,74,0.95), rgba(14,152,74,0.95)), url('http://www.aandacontrol.com/asset/images/bg2.jpg') no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-image3 {
  background: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0)), url('http://www.aandacontrol.com/asset/images/bg3.png') no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-image4 {
  background: linear-gradient(to right, rgba(126,223,251,.9), rgba(126,223,251,.9)), url('http://www.aandacontrol.com/asset/images/bg4.jpg') no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-image-about {
  background: url('http://www.aandacontrol.com/asset/images/compay_profile_cover.png') no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-image-ref {
  background: url('http://www.aandacontrol.com/asset/images/PAST-WORK_cover.png') no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-image-news {
  background: url('http://www.aandacontrol.com/asset/images/news_cover.png') no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-g-green{
  background: linear-gradient(120deg, #81B556 30%, #ffffff 50%);
}
.bg-white-green{
  background: -webkit-linear-gradient(180deg, #ffffff 6%, rgba(255, 255, 255, 0) 60%) , linear-gradient(119deg, #ffffff 8%, #81B556 30%);
  background: -o-linear-gradient(180deg, #ffffff 6%, rgba(255, 255, 255, 0) 60%) , linear-gradient(119deg, #ffffff 8%, #81B556 30%);
  background: -moz-linear-gradient(180deg, #ffffff 6%, rgba(255, 255, 255, 0) 60%) , linear-gradient(119deg, #ffffff 8%, #81B556 30%);
  background: linear-gradient(180deg, #ffffff 6%, rgba(255, 255, 255, 0) 60%) , linear-gradient(119deg, #ffffff 8%, #81B556 30%);
}
.bg-white-transparent{
  background: linear-gradient(120deg,white 50%, transparent 80%);
}
.screen50 {
  height: 50vh;
}
.screen70 {
  height: 70vh;
}
.screen100 {
  height: 100vh;
}

/*------------------------------------------------------------------
[ button ]*/
.btn-blue{
  font-weight: bold;
  color: #FFF;
  border: 0px;
  border-radius: 5px;
  background-image: linear-gradient(to top, #3298dc, #61c9f0);
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ1NSA0NTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1NSA0NTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgY2xhc3M9IiI+PGc+PHBhdGggZD0iTTIyNy41LDBDMTAxLjg1NSwwLDAsMTAxLjg1NSwwLDIyNy41UzEwMS44NTUsNDU1LDIyNy41LDQ1NVM0NTUsMzUzLjE0NSw0NTUsMjI3LjVTMzUzLjE0NSwwLDIyNy41LDB6IE0xOTkuNDc2LDM1NS41ODkgIGwtMjEuMjQ4LTIxLjE3OEwyODQuNzkxLDIyNy41TDE3OC4yMjgsMTIwLjU4OWwyMS4yNDgtMjEuMTc4TDMyNy4xNDgsMjI3LjVMMTk5LjQ3NiwzNTUuNTg5eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMEU5ODRBIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+") !important;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next{
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ1NSA0NTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1NSA0NTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PGc+PHBhdGggZD0iTTIyNy41LDBDMTAxLjg1NSwwLDAsMTAxLjg1NSwwLDIyNy41UzEwMS44NTUsNDU1LDIyNy41LDQ1NVM0NTUsMzUzLjE0NSw0NTUsMjI3LjVTMzUzLjE0NSwwLDIyNy41LDB6IE0yNzYuNzcyLDMzNC40MTEgIGwtMjEuMjQ4LDIxLjE3OEwxMjcuODUyLDIyNy41TDI1NS41MjQsOTkuNDExbDIxLjI0OCwyMS4xNzhMMTcwLjIwOSwyMjcuNUwyNzYuNzcyLDMzNC40MTF6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiMwRTk4NEEiIGRhdGEtb2xkX2NvbG9yPSIjMDAwMDAwIj48L3BhdGg+PC9nPiA8L3N2Zz4=") !important;
}

/*------------------------------------------------------------------
[ Toggle Switch ]*/

.onoffswitch {
    position: relative;
    width: 65px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
}
.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0; 
    line-height: 30px;
    font-size: 14px; 
    color: white; 
    font-family: Trebuchet, Arial, sans-serif; 
    font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "TH";
    padding-left: 10px;
    color: #999999;
    box-shadow: inset 0 1px 3px 0 var(--black-50);
    background-color: #ececec;
}
.onoffswitch-inner:after {
    content: "ENG";
    padding-right: 10px;
    box-shadow: inset 0 1px 3px 0 var(--black-50);
    background-color: #ececec;
    color: #999999;
    text-align: right;
}
.onoffswitch-switch {
    display: block; width: 18px; margin: 6px;
    background: #7EDFFB;
    position: absolute; top: 0; bottom: 0;
    right: 35px;
    border: 4px solid  #d8d8d8;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
}
/* end Toggle Switch */

@media only screen and (max-width : 991.98px){  
  .head-title{
    font-size: 1.2em;
  }
}

/*------------------------------------------------------------------
[ hover ]*/

.hov1:hover{
  color: #0E984A;
  cursor: pointer;
}

.hov-bg1:hover{
  background-color: #0E984A !important;
  color: #FFF;
  cursor: pointer;
}

/*------------------------------------------------------------------
[ other ]*/
.img-border{
  border: solid 5px white;
}
.box-shadow:after {
  content:"";
  position:absolute;
  width:100%;
  bottom:1px;
  z-index:-1;
  transform:scale(.9);
  box-shadow: 0px 0px 8px 2px #909090;
}
.slide-title{
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.swiper-pagination-bullet{
  width: 12px !important;
  height: 12px !important;
}

.swiper-pagination-bullet-active{
  background: #0E984A !important;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
  right: 0px !important;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next{
  left: 0px !important;
}